import React from "react";

import Layout from "@components/Layout";
import { SectionTopHeader, SectionWrapper } from "@components/Section";

import {
  SectionParagraph,
  SectionHeading,
  SectionHeader
} from "@components/Section/SectionHeader";

import Seo from "@components/SEO";

import Vector from "@components/Vector";

function NotFoundPage() {
  return (
    <Layout>
      <Seo />
      <SectionTopHeader>
        <SectionWrapper>
          <SectionHeader>Oops</SectionHeader>
          <SectionHeading.h1>Page not found</SectionHeading.h1>
          <SectionParagraph>Looks like the page you are requesting is not here!</SectionParagraph>
        </SectionWrapper>
        <Vector name="arabica-23" alt="A hero image illustration depicting a creature finding an error"/>
      </SectionTopHeader>
    </Layout>
  );
}

export default NotFoundPage;
